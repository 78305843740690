import './App.css';
import React, { useState } from 'react';
import { Container, Image } from 'react-bootstrap';
import Foto from './images/Perfil.jpg';
import Insta from './images/Instagram.jpg';
import Logo from './images/Logosf.png'; // Mantido como .png
import Gaming from './images/Gaming.jpg';
import Icarolanes from './images/icarolanes.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const links = [
    { name: 'LinkedIn', url: 'https://bit.ly/3Nuw0ZX', icon: Foto, bg: Insta },
    { name: 'GitHub', url: 'https://github.com/icarolanes', icon: Icarolanes, bg: '' },
    { name: 'Instagram', url: 'https://www.instagram.com/icarolanes/', icon: Insta, bg: Insta },
    { name: 'Email', url: 'mailto:icaro_lanes@hotmail.com', icon: Foto, bg: Insta },
    { name: 'Whatsapp', url: 'https://bit.ly/3KSepJq', icon: Insta, bg: Foto },
    { name: 'Twitch', url: 'https://twitch.tv/icarolanes', icon: Logo, bg: Gaming },
  ];

  const [image, setImage] = useState(Foto);
  const [background, setBackground] = useState('');
  const [isSpinning, setIsSpinning] = useState(false); // Estado para o giro
  const [isFading, setIsFading] = useState(false); // Estado para o fade

  const handleHover = (data) => {
    setIsSpinning(true); // Ativa o giro
    setIsFading(true); // Ativa o fade
    setImage(data.icon); // Troca a imagem
    setBackground(data.bg); // Troca o fundo

    // Desativa as animações após um tempo
    setTimeout(() => {
      setIsSpinning(false);
      setIsFading(false);
    }, 600); // Duração total das animações (0.6s)
  };

  return (
    <Container className="corpo" fluid>
      <Image
        src={background || Foto} // Usa Foto como fallback para o background
        className={`background ${isFading ? 'fade' : ''}`} // Aplica fade dinamicamente
        fluid
      />
      <div className="scontainer">
        <img
          src={image}
          alt="Avatar de Icaro Lanes"
          className={`avatar ${isSpinning ? 'spin' : ''}`} // Aplica giro dinamicamente
        />
        <h1>Icaro Lanes</h1>
        <div className="links">
          {links.map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={() => handleHover(link)}
              onMouseLeave={() => handleHover({ icon: Foto, bg: '' })}
              className="link"
              aria-label={`Visitar ${link.name} de Icaro Lanes`}
            >
              {link.name}
            </a>
          ))}
        </div>
      </div>
    </Container>
  );
}

export default App;